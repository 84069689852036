import Cookies from 'js-cookie'

const proxyWebsite = 'https://lpt-express.52imile.cn' // 测试官网域名
const prodProxyWebsite = 'https://express.imile.com'
const devProxyWebsite = 'https://dev-express.52imile.cn'
const lineproxy = 'https://en.imile.com' // 本地联调，上线后 delete
const testproxy = 'https://test-www.52imile.cn' // 本地联调，上线后 delete

// 南非ip提交
export const submitAPI = async (data) => {
  // const url = `${testproxy}/hera/no/auth/update/consigneeId`
  const url = `/hera/no/auth/update/consigneeId`
  return window
    .fetch(url, {
      method: 'post',
      headers: { 'Content-Type': 'application/json', lang: Cookies.get('EXPRESS_LANG') },
      body: JSON.stringify(data),
    })
    .then((res) => {
      return res.json()
    })
    .catch((err) => {
      return err
    })
}

// 查询相同订单
export const sameOrderAPI = async (waybillNo: string) => {
  // const url = `${testproxy}/hera/no/auth/get/same/consignee?waybillNo=${waybillNo}`
  const url = `/hera/no/auth/get/same/consignee?waybillNo=${waybillNo}`
  return window
    .fetch(url, {
      method: 'get',
      headers: { 'Content-Type': 'application/json', lang: Cookies.get('EXPRESS_LANG') },
    })
    .then((res) => {
      return res.json()
    })
    .catch((err) => {
      return err
    })
}

// id信息详情
export const baseInfoAPI= async (waybillNo: string) => {
  // const url = `${testproxy}/hera/no/auth/get/consigneeId?waybillNo=${waybillNo}`
  const url = `/hera/no/auth/get/consigneeId?waybillNo=${waybillNo}`
  return window
    .fetch(url, {
      method: 'get',
      headers: { 'Content-Type': 'application/json', lang: Cookies.get('EXPRESS_LANG') },
    })
    .then((res) => {
      return res.json()
    })
    .catch((err) => {
      return err
    })
}