import React, { useState, useEffect, useCallback } from 'react'
import { graphql } from 'gatsby'
import { Form, message } from 'antd'
import type { CheckboxValueType } from 'antd/es/checkbox/Group'
import Header from '@/components/InformationCollection/header'
import Content from '@/components/InformationCollection/content'
import useSuccessPage from '@/components/InformationCollection/useSuccessPage'
import { Box, useMediaQuery } from '@mui/material'
import { InformationProps, Details } from '@/components/InformationCollection/interface'
import { getUrlParam } from '@/components/PrivacyPolicy/config'
import { sameOrderAPI, baseInfoAPI } from '@/components/InformationCollection/api'

const InformationCollection = (pageProps) => {
  const [step, setStep] = useState<'add' | 'edit' | 'showSuccess'>('add')
  const [submitInfo, setSubmitInfo] = useState<Details>({
    trackingNO: '',
    consigneeId: '',
  })
  const [form] = Form.useForm()
  const [idOrigin, setIdOrigin] = useState<string | undefined>(undefined)
  const [order, setOrder] = useState<string>('')
  const [checkedList, setCheckedList] = useState<CheckboxValueType[]>([])
  const [sameOrderList, setSameOrderList] = useState<string[]>([])
  const isPc = useMediaQuery('@media (min-width: 800px)')
  const informationCollectionLocale = JSON.parse(pageProps.data?.informationCollectionLocale?.data)

  const props: InformationProps = {
    step,
    submitInfo,
    setStep,
    setSubmitInfo,
    informationCollectionLocale,
    form,
    order,
    idOrigin,
    sameOrderList,
    checkedList,
    setCheckedList,
  }

  const { content } = useSuccessPage(props)

  const init = () => {
    const waybillNoBase64 = getUrlParam('waybillNo')
    const waybillNo = window.atob(waybillNoBase64)
    setOrder(waybillNo)
    form.setFieldsValue({
      isAgree: true,
    })
  }

  const querySameOrder = useCallback(async (waybillNo) => {
    const res = await sameOrderAPI(waybillNo)
    if (res.status === 'success') {
      setSameOrderList(res.resultObject || [])
      setCheckedList(res.resultObject || [])
    } else {
      message.error(res.message || res.status)
      setSameOrderList([])
    }
  }, [])

  const queryBaseInfo = useCallback(
    async (waybillNo) => {
      const res = await baseInfoAPI(waybillNo)
      if (res.status === 'success') {
        step !== 'edit' && res.resultObject && setStep('showSuccess')
        setSubmitInfo({
          trackingNO: order || '',
          consigneeId: res.resultObject || '',
        })
        if (res.resultObject) {
          setSameOrderList([])
          setCheckedList([])
        }
        form.setFieldsValue({
          consigneeId: res.resultObject || undefined,
        })
        setIdOrigin(res.resultObject || undefined)
      } else {
        message.error(res.message || res.status)
      }
    },
    [step, form, order],
  )

  const queryData = useCallback(async () => {
    if (order) {
      await querySameOrder(order)
      queryBaseInfo(order)
    }
  }, [order, queryBaseInfo, querySameOrder])

  useEffect(() => {
    init()
  }, [])

  useEffect(() => {
    queryData()
  }, [queryData])

  return (
    <Box sx={{ width: '100%', height: '100vh', bgcolor: '#E4E5ED' }}>
      <Box
        sx={{ display: 'flex', justifyContent: 'center', padding: '16px 0 24px', height: '100%' }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: isPc ? '900px' : '100%',
            borderRadius: '8px',
            bgcolor: isPc ? 'white' : '#E4E5ED',
            height: '100%',
          }}
        >
          <Header />
          {step === 'showSuccess' ? content : <Content {...props} />}
        </Box>
      </Box>
    </Box>
  )
}
export default InformationCollection

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { ns: { in: ["home"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
        }
      }
    }
    commonLocale: locale(ns: { eq: "common" }, language: { eq: $language }) {
      data
    }
    headerLocale: locale(ns: { eq: "header" }, language: { eq: $language }) {
      data
    }
    footerLocale: locale(ns: { eq: "footer" }, language: { eq: $language }) {
      data
    }
    languageLocale: locale(ns: { eq: "language" }, language: { eq: $language }) {
      data
    }
    informationCollectionLocale: locale(
      ns: { eq: "informationCollection" }
      language: { eq: $language }
    ) {
      data
    }
  }
`
