import React from 'react'
import { Button } from 'antd'
import { Box, useMediaQuery } from '@mui/material'
import { Sucess } from '@imile/icons'

const useSuccessPage = ({ setStep, submitInfo }) => {
  const isPc = useMediaQuery('@media (min-width: 800px)')

  const handleClick = () => {
    setStep('edit')
  }

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        py: isPc ? '100px' : '28px',
        bgcolor: '#fff',
        margin: isPc ? 'initial' : '0 16px',
        '.success-info': {
          fontSize: 18,
          fontWeight: 600,
          lineHeight: '26px',
          mt: '20px',
        },
      }}
    >
      <Sucess fontSize={56} />
      <div className='success-info'>Submit successfully</div>
      <Box
        sx={{
          width: isPc ? '408px' : 'initial',
          height: '96px',
          border: '1px solid #EDEFF5',
          borderRadius: '8px',
          padding: '16px',
          margin: '16px',
          '.box': {
            display: 'flex',
            lineHeight: '26px',
            fontSize: '14px',
            margin: '6px 0',
            '.tit': {
              width: '160px',
              color: '#878B9C',
            },
            '.value': {
              color: '#101117',
              fontWeight: 600,
            },
          },
        }}
      >
        <div className='box'>
          <div className='tit'>Tracking NO.</div>
          <div className='value'>{submitInfo.trackingNO}</div>
        </div>
        <div className='box'>
          <div className='tit'>identity information</div>
          <div className='value'>{submitInfo.consigneeId}</div>
        </div>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          px: '16px',
          button: {
            width: '96px',
            height: '38px',
            borderRadius: '6px',
            fontWeight: 500,
            fontSize: ['16px', '14px'],
            color: '#101117',
            bgcolor: '#EDEFF5',
            border: 0,
          },
          '.ant-btn-default': {
            width: isPc ? 'max-content' : '100%',
            bgcolor: '#EDEFF5',
            fontWeight: 500,
            color: '#101117',
            '&:hover': {
              bgcolor: '#EDEFF5',
              color: '#101117',
            },
          },
        }}
      >
        <Button onClick={handleClick}>Resubmit</Button>
      </Box>
    </Box>
  )

  return {
    content,
  }
}

export default useSuccessPage
